<template>
    <div
        v-if="this.isReady"
        class="absolute p-5 top-0 w-full md:w-1/2 lg:w-128"
    >
        <div class="relative w-full h-full z-10 bg-white rounded shadow-lg p-5">
            <div class="py-4 flex-1 space-y-2">
                <div class="flex flex-row justify-between">
                    <h3 class="text-sm font-medium">
                        <h3 class="">{{ title }}</h3>
                    </h3>
                    <button
                        type="button"
                        class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        @click="close"
                    >
                        <span class="sr-only">Close</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <p class="text-sm text-gray-500">{{ description }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { XIcon } from "@heroicons/vue/outline";
const { mapState } = createNamespacedHelpers("playground");

export default {
    name: "PlaygroundDetailView",
    props: {
        playgroundUuid: String,
    },
    computed: {
        playground() {
            return this.$store.getters["playground/playgroundByUuid"](
                this.playgroundUuid
            );
        },
        title() {
            return this.playground.properties.title;
        },
        description() {
            return this.playground.properties.description;
        },
        ...mapState({
            isReady: (state) => state.isReady,
        }),
    },
    methods: {
        center: function () {
            if (this.isReady) {
                this.$store.commit(
                    "center",
                    this.playground.geometry.coordinates
                );
            }
        },
        close: function () {
            this.$router.push({
                name: "home",
            });
        },
    },
    updated: function () {
        this.center();
    },
    mounted: function () {
        this.center();
    },
    unmounted() {
        this.$store.commit("clear");
    },
    components: { XIcon },
};
</script>
