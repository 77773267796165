<template>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <Address
            v-for="address in addressCollection"
            :address="address"
        ></Address>
    </div>
</template>

<script>
import Address from "./Address";

export default {
    name: "AddressCollection",
    props: {
        addressCollection: Object,
    },
    components: { Address },
};
</script>
