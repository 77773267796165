<template>
    <div
        class="truncate text-blue-600 hover:text-blue-800 visited:text-purple-600"
    >
        <a :href="website.url">
            {{ website.title }}
        </a>
    </div>
</template>

<script>
export default {
    name: "Website",
    props: {
        website: Object,
    },
};
</script>
