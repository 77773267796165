import * as types from "./mutation_types";

export default {
    [types.PLAYGROUND_FINISH_LOADING_ALL](state) {
        Object.assign(state, { isLoadingAll: false, isReady: true });
    },

    [types.PLAYGROUND_HIGHLIGHT](state, playgroundUuid) {
        Object.assign(state, { highlightedPlaygroundUuid: playgroundUuid });
    },

    [types.PLAYGROUND_SHOW_SET_ERROR](state, error) {
        Object.assign(state, { error });
    },

    [types.PLAYGROUND_SHOW_SET_RETRIEVED_ALL](state, playgrounds) {
        Object.assign(state, { playgrounds });
    },

    [types.PLAYGROUND_START_LOADING_ALL](state) {
        Object.assign(state, { isLoadingAll: true });
    },

    [types.PLAYGROUND_UN_HIGHLIGHT](state) {
        Object.assign(state, { highlightedPlaygroundUuid: null });
    },
};
