<template></template>

<script>
import { Map } from "mapbox-gl";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    name: "Playgrounds",
    props: {
        map: Map,
        pspConfiguration: Object,
    },

    methods: {
        ...mapActions({
            highlight: "playground/highlight",
            unHighlight: "playground/unHighlight",
            retrieveAll: "playground/retrieveAll",
        }),
        initializeMap: function () {
            this.map.on("load", () => {
                this.map.loadImage(
                    require("@/assets/playgrounds/marker.png"),
                    (error, image) => {
                        if (error) throw error;
                        this.map.addImage("playground_marker", image, {
                            sdf: true,
                        });
                        this.map.addSource("playground_source", {
                            type: "geojson",
                            promoteId: "uuid",
                            data: {
                                type: "FeatureCollection",
                                features: this.playgrounds,
                            },
                        });
                        this.map.addLayer({
                            id: "playground_layer",
                            type: "symbol",
                            source: "playground_source",
                            layout: {
                                "icon-image": "playground_marker",
                                "icon-anchor": "bottom",
                                "icon-size": 0.4,
                            },
                            paint: {
                                "text-color": "#ffffff",
                                "icon-color": [
                                    "case",
                                    [
                                        "boolean",
                                        ["feature-state", "highlight"],
                                        false,
                                    ],
                                    "#4264FB",
                                    "#748dfc",
                                ],
                                "icon-opacity": [
                                    "case",
                                    [
                                        "boolean",
                                        ["feature-state", "highlight"],
                                        false,
                                    ],
                                    0.9,
                                    0.7,
                                ],
                            },
                        });
                    }
                );
            });
            this.map.on("mouseenter", "playground_layer", (e) => {
                this.highlight(e.features[0].properties.uuid);
                this.map.getCanvas().style.cursor = "pointer";
            });
            this.map.on("mouseleave", "playground_layer", () => {
                this.unHighlight();
                this.map.getCanvas().style.cursor = "";
            });
            this.map.on("click", "playground_layer", (e) => {
                this.$router.push({
                    name: "playground_detail",
                    params: { playgroundUuid: e.features[0].properties.uuid },
                });
            });
        },
    },

    computed: {
        ...mapState("playground", ["playgrounds", "highlightedPlaygroundUuid"]),
        ...mapGetters("playground", ["playgroundUuids"]),
    },

    watch: {
        map: {
            handler(map) {
                if (null !== map) {
                    this.initializeMap();
                }
            },
        },
        playgroundUuids: {
            handler() {
                if (
                    null !== this.map &&
                    typeof this.map.getSource("playground_source") !==
                        "undefined"
                ) {
                    this.map.getSource("playground_source").setData({
                        type: "FeatureCollection",
                        features: this.playgrounds,
                    });
                }
            },
        },
        highlightedPlaygroundUuid: {
            handler(
                newHighlightedPlaygroundUuid,
                oldHighlightedPlaygroundUuid
            ) {
                if (oldHighlightedPlaygroundUuid !== null) {
                    this.map.setFeatureState(
                        {
                            source: "playground_source",
                            id: oldHighlightedPlaygroundUuid,
                        },
                        { highlight: false }
                    );
                }
                if (newHighlightedPlaygroundUuid !== null) {
                    this.map.setFeatureState(
                        {
                            source: "playground_source",
                            id: newHighlightedPlaygroundUuid,
                        },
                        { highlight: true }
                    );
                }
            },
        },
    },

    created() {
        this.retrieveAll(this.pspConfiguration?.pspUserUuid ?? null);
    },
};
</script>

<style></style>
