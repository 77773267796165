import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.scss";

const mapModuleContainerId = "map-module";
const rootContainer = document.getElementById(mapModuleContainerId);

if (rootContainer != null) {
    const mapConfigurationRaw = rootContainer.dataset.mapConfiguration;

    if (undefined === mapConfigurationRaw) {
        throw "map module is missing a map configuration.";
    }

    const mapConfiguration = JSON.parse(mapConfigurationRaw);
    // todo: validate mapConfiguration

    createApp(App, { mapConfiguration: mapConfiguration })
        .use(store)
        .use(router)
        .mount(`#${mapModuleContainerId}`);
} else {
    throw `The map-module script is loaded, but there is no element with the id ${mapModuleContainerId}`;
}
