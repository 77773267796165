<template>
    <div class="prose prose-sm prose-p:leading-tight" v-html="sanitized"></div>
</template>
<script>
import DOMPurify from "dompurify";

export default {
    name: "Description",
    props: {
        description: String,
    },
    computed: {
        sanitized() {
            return DOMPurify.sanitize(this.description);
        },
    },
};
</script>
