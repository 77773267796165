import * as types from "./mutation_types";

export default {
    [types.MUNICIPAL_SERVICE_FINISH_LOADING_ALL](state) {
        Object.assign(state, { isLoadingAll: false, isReady: true });
    },

    [types.MUNICIPAL_SERVICE_HIGHLIGHT](state, municipalServiceUuid) {
        Object.assign(state, {
            highlightedMunicipalServicesUuid: municipalServiceUuid,
        });
    },

    [types.MUNICIPAL_SERVICE_SHOW_SET_ERROR](state, error) {
        Object.assign(state, { error });
    },

    [types.MUNICIPAL_SERVICE_SHOW_SET_RETRIEVED_ALL](state, municipalServices) {
        Object.assign(state, { municipalServices });
    },

    [types.MUNICIPAL_SERVICE_START_LOADING_ALL](state) {
        Object.assign(state, { isLoadingAll: true });
    },

    [types.MUNICIPAL_SERVICE_UN_HIGHLIGHT](state) {
        Object.assign(state, { highlightedMunicipalServicesUuid: null });
    },
};
