const mapboxgl = require("mapbox-gl");

export default {
    municipalServiceUuids(state) {
        return state.municipalServices.map(
            (municipalService) => municipalService.properties.uuid
        );
    },
    municipalServiceByUuid: (state) => (uuid) => {
        return state.municipalServices.find(
            (municipalService) => municipalService.properties.uuid === uuid
        );
    },
    bounds: (state) => () => {
        if (0 === state.municipalServices.length) {
            return null;
        }

        const bounds = new mapboxgl.LngLatBounds(
            state.municipalServices[0].geometry.coordinates,
            state.municipalServices[0].geometry.coordinates
        );

        for (const municipalService of state.municipalServices) {
            bounds.extend(municipalService.geometry.coordinates);
        }

        return bounds;
    },
};
