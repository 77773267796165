<template>
    <div
        class="truncate text-blue-600 hover:text-blue-800 visited:text-purple-600"
    >
        <a :href="'tel:' + phoneNumber.normalized">
            {{ phoneNumber.formatted }}
        </a>
    </div>
</template>

<script>
export default {
    name: "PhoneNumber",
    props: {
        phoneNumber: Object,
    },
};
</script>
