<template>
    <div class="truncate px-2">{{ period.start }}-{{ period.end }} Uhr</div>
</template>
<script>
export default {
    name: "Period",
    props: {
        period: {},
    },
};
</script>
