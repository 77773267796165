<template>
    <div class="space-y-0">
        <div v-for="contactElement in contactElements" class="flex text-sm">
            <div class="w-1/5 text-clip font-medium">
                {{ contactElement.title }}
            </div>
            <div class="w-4/5">
                <div v-if="contactElement.type === 'PHONE_NUMBER'">
                    <PhoneNumber :phone-number="contactElement.value" />
                </div>
                <div v-if="contactElement.type === 'WEBSITE'">
                    <Website :website="contactElement.value" />
                </div>
                <div v-if="contactElement.type === 'EMAIL'">
                    <Email :email="contactElement.value" />
                </div>
                <div v-if="contactElement.note !== null" class="text-gray-700">
                    {{ contactElement.note }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Email from "./Email";
import PhoneNumber from "./PhoneNumber";
import Website from "./Website";

export default {
    name: "ContactElementCollection",
    components: { Email, PhoneNumber, Website },
    props: {
        contactElements: {},
    },
};
</script>
