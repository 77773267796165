<template>
    <div
        v-if="this.isReady"
        class="absolute top-0 w-full h-full flex justify-end"
    >
        <div
            class="relative w-full h-full z-10 opacity-80 overflow-x-hidden overflow-y-scroll w-2/3"
        >
            <div>
                <div
                    class="h-24 w-full bg-white border-l-4 border-municipal_dark_blue"
                ></div>
                <div class="flex w-full">
                    <img
                        class="h-24"
                        src="@/assets/municipal_service/semicircle-mask-outline.svg"
                    />
                    <div class="bg-white w-full"></div>
                </div>
            </div>
            <div
                class="py-4 flex-1 space-y-5 bg-white p-5 border-l-4 border-municipal_dark_blue"
            >
                <div class="flex flex-row justify-between">
                    <h2 class="text-lg text-lime-400 font-medium">
                        {{ municipalService.title }}
                    </h2>
                    <button
                        type="button"
                        class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        @click="close"
                    >
                        <span class="sr-only">Close</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                <Description
                    v-if="municipalService.description !== null"
                    :description="municipalService.description"
                />

                <AddressCollection
                    v-if="municipalService.addresses.length > 0"
                    :address-collection="municipalService.addresses"
                />

                <PropertyCollection
                    class="pb-5"
                    v-if="municipalService.accessibility !== null"
                    :propertyCollection="municipalService.accessibility"
                />
                <PropertyCollection
                    class="pt-5"
                    v-if="municipalService.transportation !== null"
                    :propertyCollection="municipalService.transportation"
                />

                <template v-for="department in municipalService.departments">
                    <Department :department="department"></Department>
                </template>

                <OpeningHours
                    v-if="municipalService.openingHours !== null"
                    :opening-hours="municipalService.openingHours"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { XIcon } from "@heroicons/vue/outline";
import PropertyCollection from "./PropertyCollection";
import Department from "./Department";
import Address from "./Address";
import AddressCollection from "./AddressCollection";
import Description from "./Description";
import OpeningHours from "./OpeningHours/OpeningHours";

const { mapState } = createNamespacedHelpers("municipalService");

export default {
    name: "MunicipalServiceDetailView",
    props: {
        municipalServiceUuid: String,
    },
    computed: {
        municipalService() {
            return this.$store.getters[
                "municipalService/municipalServiceByUuid"
            ](this.municipalServiceUuid);
        },
        ...mapState({
            isReady: (state) => state.isReady,
        }),
    },
    methods: {
        center: function () {
            if (this.isReady) {
                this.$store.commit(
                    "center",
                    this.municipalService.geometry.coordinates
                );
            }
        },
        close: function () {
            this.$router.push({
                name: "home",
            });
        },
    },
    updated: function () {
        this.center();
    },
    mounted: function () {
        this.center();
    },
    unmounted() {
        this.$store.commit("clear");
    },
    components: {
        AddressCollection,
        Address,
        XIcon,
        PropertyCollection,
        Department,
        Description,
        OpeningHours,
    },
};
</script>
