const mapboxgl = require("mapbox-gl");

export default {
    playgroundUuids(state) {
        return state.playgrounds.map(
            (playground) => playground.properties.uuid
        );
    },
    playgroundByUuid: (state) => (uuid) => {
        return state.playgrounds.find(
            (playground) => playground.properties.uuid === uuid
        );
    },
    bounds: (state) => () => {
        if (0 === state.playgrounds.length) {
            return null;
        }

        const bounds = new mapboxgl.LngLatBounds(
            state.playgrounds[0].geometry.coordinates,
            state.playgrounds[0].geometry.coordinates
        );

        for (const playground of state.playgrounds) {
            bounds.extend(playground.geometry.coordinates);
        }

        return bounds;
    },
};
