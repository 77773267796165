<template>
    <div class="space-y-2">
        <h4 class="text-gray-900 font-bold">Kontaktpersonen</h4>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <ContactPerson
                v-for="contactPerson in contactPersonCollection"
                :contact-person="contactPerson"
            ></ContactPerson>
        </div>
    </div>
</template>

<script>
import ContactPerson from "./ContactPerson";

export default {
    name: "ContactPersonCollection",
    props: {
        contactPersonCollection: Object,
    },
    components: { ContactPerson },
};
</script>
