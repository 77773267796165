<template>
    <div
        class="truncate text-blue-600 hover:text-blue-800 visited:text-purple-600"
    >
        <a :href="'mailto:' + email.value">
            {{ email.value }}
        </a>
    </div>
</template>

<script>
export default {
    name: "Email",
    props: {
        email: Object,
    },
};
</script>
