import * as types from "./mutation_types";

export default {
    [types.CLIENT_CONFIGURATION_FINISH_LOADING_ONE](state) {
        Object.assign(state, { isLoadingOne: false, isReady: true });
    },

    [types.CLIENT_CONFIGURATION_SHOW_SET_ERROR](state, error) {
        Object.assign(state, { error });
    },

    [types.CLIENT_CONFIGURATION_SHOW_SET_RETRIEVED_ONE](
        state,
        clientConfiguration
    ) {
        Object.assign(state, {
            activeModules: clientConfiguration.activeModules,
            accessToken: clientConfiguration.accessToken,
            style: clientConfiguration.style,
        });
    },

    [types.CLIENT_CONFIGURATION_START_LOADING_ONE](state) {
        Object.assign(state, { isLoadingOne: true });
    },
};
