import { createStore } from "vuex";

import { playgroundStoreModule } from "@/Layers/Playgrounds/Store/playground_store";
import { trackStoreModule } from "@/Layers/Tracks/Store/track_store";
import { clientConfigurationStoreModule } from "./ClientConfiguration/client_configuration_store";
import { municipalServiceStoreModule } from "@/Layers/MunicipalService/Store/municipal_service_store";

export interface State {
    focus: null | object;
    center: null | object;
    bounds: null | object;
    interactionEnabled: boolean;
}

export default createStore<State>({
    state: {
        focus: null,
        center: null,
        bounds: null,
        interactionEnabled: true,
    },
    modules: {
        playground: playgroundStoreModule,
        track: trackStoreModule,
        municipalService: municipalServiceStoreModule,
        clientConfiguration: clientConfigurationStoreModule,
    },
    mutations: {
        center(state, coordnates) {
            state.bounds = null;
            state.center = coordnates;
            state.focus = {
                center: coordnates,
                bounds: null,
            };
        },
        fitBounds(state, bounds) {
            state.center = null;
            state.bounds = bounds;
            state.focus = {
                center: null,
                bounds: bounds,
            };
        },
        clear(state) {
            state.center = null;
            state.bounds = null;
            state.focus = null;
        },
        enableInteraction(state, interactionEnabled) {
            state.interactionEnabled = interactionEnabled;
        },
    },
});
