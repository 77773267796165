import track_1 from "./tracks/track_1.json";
import track_2 from "./tracks/track_2.json";
import track_3 from "./tracks/track_3.json";
const mapboxgl = require("mapbox-gl");

export type State = {
    tracks: any;
    highlightedTrackUuid: string | null;
    isReady: boolean;
};

export const trackStoreModule = {
    namespaced: true,
    state: {
        tracks: [...track_1.features, ...track_2.features, ...track_3.features],
        highlightedTrackUuid: null,
        isReady: true,
    },
    getters: {
        trackUuids(state: State) {
            return state.tracks.map((track: any) => track.properties.uuid);
        },
        trackByUuid: (state: State) => (uuid: String) => {
            return state.tracks.find(
                (track: any) => track.properties.uuid === uuid
            );
        },
        boundsByUuid: (state: State) => (uuid: String) => {
            const track = state.tracks.find(
                (track: any) => track.properties.uuid === uuid
            );

            const coordinates = track.geometry.coordinates[0];

            // Create a 'LngLatBounds' with both corners at the first coordinate.
            const bounds = new mapboxgl.LngLatBounds(
                coordinates[0],
                coordinates[0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
            for (const coord of coordinates) {
                bounds.extend(coord);
            }

            return bounds;
        },
        bounds: (state: State, getters: any) => () => {
            const bounds = getters.boundsByUuid(
                state.tracks[0].properties.uuid
            );
            for (const track of state.tracks) {
                bounds.extend(getters.boundsByUuid(track.properties.uuid));
            }

            return bounds;
        },
    },
    mutations: {
        highlight(state: State, trackUuid: string) {
            state.highlightedTrackUuid = trackUuid;
        },

        unhighlight(state: State) {
            state.highlightedTrackUuid = null;
        },
    },
    actions: {},
};
