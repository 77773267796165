import * as actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export type State = {
    error: string;
    highlightedMunicipalServiceUuid: string | null;
    isLoadingAll: boolean;
    isReady: boolean;
    municipalServices: any;
};

export const municipalServiceStoreModule = {
    namespaced: true,
    state: {
        error: "",
        highlightedMunicipalServiceUuid: null,
        isLoadingAll: false,
        isReady: false,
        municipalServices: [],
    },
    getters: {
        ...getters,
    },
    mutations: {
        ...mutations,
    },
    actions,
};
