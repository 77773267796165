import * as actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export type State = {
    error: string;
    isLoadingOne: boolean;
    isReady: boolean;
    activeModules: Array<string>;
    accessToken: string;
    style: string;
};

export const clientConfigurationStoreModule = {
    namespaced: true,
    state: {
        error: "",
        isLoadingOne: false,
        isReady: false,
        activeModules: [],
        accessToken: "",
        style: "",
    },
    getters: {
        ...getters,
    },
    mutations: {
        ...mutations,
    },
    actions,
};
