<template>
    <Map
        v-if="isReady"
        :active-modules="activeModules"
        :style="style"
        :access-token="accessToken"
        :map-configuration="mapConfiguration"
    ></Map>
</template>

<script>
import Map from "./Map.vue";
import { mapActions } from "vuex";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("clientConfiguration");

export default {
    name: "App",
    props: {
        mapConfiguration: Object,
    },
    computed: {
        ...mapState(["activeModules", "isReady", "style", "accessToken"]),
    },
    methods: {
        ...mapActions({
            retrieveOne: "clientConfiguration/retrieveOne",
        }),
    },
    components: { Map },
    created() {
        this.retrieveOne(this.mapConfiguration.clientKey);
    },
};
</script>
