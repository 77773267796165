import fetch from "@/utils/api/fetch";
import * as types from "./mutation_types";

export const retrieveAll = ({ commit }, pspUserUuid) => {
    commit(types.PLAYGROUND_START_LOADING_ALL);

    return fetch(
        `api/playgrounds${
            pspUserUuid !== null ? "?pspUserUuid=" + pspUserUuid : ""
        }`
    )
        .then((response) => response.json())
        .then((data) => {
            commit(types.PLAYGROUND_FINISH_LOADING_ALL);
            commit(
                types.PLAYGROUND_SHOW_SET_RETRIEVED_ALL,
                data["hydra:member"]
            );
        })
        .catch((e) => {
            commit(types.PLAYGROUND_FINISH_LOADING_ALL);
            commit(types.PLAYGROUND_SHOW_SET_ERROR, e.message);
        });
};

export const highlight = ({ commit }, uuid) => {
    commit(types.PLAYGROUND_HIGHLIGHT, uuid);
};

export const unHighlight = ({ commit }) => {
    commit(types.PLAYGROUND_UN_HIGHLIGHT);
};
