import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PlaygroundDetailView from "../views/PlaygroundDetailView.vue";
import TrackDetailView from "../views/TrackDetailView.vue";
import MunicipalServiceDetailView from "@/Layers/MunicipalService/views/MunicipalServiceDetailView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/playgrounds/:playgroundUuid",
        name: "playground_detail",
        component: PlaygroundDetailView,
        props: true,
    },
    {
        path: "/tracks/:trackUuid",
        name: "track_detail",
        component: TrackDetailView,
        props: true,
    },
    {
        path: "/municipal-service/:municipalServiceUuid",
        name: "municipal_service_detail",
        component: MunicipalServiceDetailView,
        props: true,
    },
];

const router = createRouter({
    history: createWebHashHistory(window.location.pathname),
    routes,
});

export default router;
