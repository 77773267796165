<template>
    <div class="border rounded p-2 text-sm overflow-hidden">
        <div>
            {{ contactPerson.name }}
        </div>
        <div>
            {{ contactPerson.position }}
        </div>
        <PhoneNumber
            v-if="contactPerson.phoneNumber !== null"
            :phone-number="contactPerson.phoneNumber"
        />

        <Email
            v-if="contactPerson.email !== null"
            :email="contactPerson.email"
        />
        <OpeningHours
            v-if="contactPerson.openingHours !== null"
            :opening-hours="contactPerson.openingHours"
        />
    </div>
</template>

<script>
import PhoneNumber from "./PhoneNumber";
import Email from "./Email";
import OpeningHours from "@/Layers/MunicipalService/views/OpeningHours/OpeningHours";

export default {
    name: "ContactPerson",
    components: { Email, PhoneNumber, OpeningHours },
    props: {
        contactPerson: Object,
    },
};
</script>
