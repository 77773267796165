<template></template>

<script>
import { Map } from "mapbox-gl";
import { mapState, mapGetters } from "vuex";

export default {
    name: "Tracks",
    props: {
        map: Map,
    },

    methods: {
        initializeMap: function () {
            this.map.on("load", () => {
                this.map.addSource("track_source", {
                    type: "geojson",
                    promoteId: "uuid",
                    data: {
                        type: "FeatureCollection",
                        features: this.tracks,
                    },
                });
                this.map.addLayer({
                    id: "track_layer",
                    type: "line",
                    source: "track_source",
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                    },
                    paint: {
                        "line-width": [
                            "case",
                            ["boolean", ["feature-state", "highlight"], false],
                            5,
                            3,
                        ],
                        "line-opacity": [
                            "case",
                            ["boolean", ["feature-state", "highlight"], false],
                            0.8,
                            0.5,
                        ],
                        "line-color": ["get", "color"],
                    },
                });
                this.map.addLayer({
                    id: "track_click_layer",
                    type: "line",
                    source: "track_source",
                    paint: {
                        "line-width": 40,
                        "line-opacity": 0,
                    },
                });
            });
            this.map.on("mousemove", "track_click_layer", (e) => {
                this.$store.commit("track/highlight", e.features[0].id);
                this.map.getCanvas().style.cursor = "pointer";
            });
            this.map.on("mouseleave", "track_click_layer", (e) => {
                this.$store.commit("track/unhighlight");
                this.map.getCanvas().style.cursor = "";
            });
            this.map.on("click", "track_click_layer", (e) => {
                this.$router.push({
                    name: "track_detail",
                    params: { trackUuid: e.features[0].properties.uuid },
                });
            });
        },
    },

    computed: {
        ...mapState("track", ["tracks", "highlightedTrackUuid"]),
        ...mapGetters("track", ["trackUuids"]),
    },

    watch: {
        map: {
            handler(map) {
                if (null !== map) {
                    this.initializeMap();
                }
            },
        },
        trackUuids: {
            handler() {
                this.map.getSource("track_source").setData({
                    type: "FeatureCollection",
                    features: this.tracks,
                });
            },
        },
        highlightedTrackUuid: {
            handler(newHighlightedTrackUuid, oldHighlightedTrackUuid) {
                if (oldHighlightedTrackUuid !== null) {
                    this.map.setFeatureState(
                        { source: "track_source", id: oldHighlightedTrackUuid },
                        { highlight: false }
                    );
                }
                if (newHighlightedTrackUuid !== null) {
                    this.map.setFeatureState(
                        { source: "track_source", id: newHighlightedTrackUuid },
                        { highlight: true }
                    );
                }
            },
        },
    },
};
</script>

<style></style>
