import fetch from "@/utils/api/fetch";
import * as types from "./mutation_types";

export const retrieveOne = ({ commit }, mapClientKey) => {
    commit(types.CLIENT_CONFIGURATION_START_LOADING_ONE);

    return fetch(`api/client_configurations/${mapClientKey}`)
        .then((response) => response.json())
        .then((data) => {
            commit(types.CLIENT_CONFIGURATION_FINISH_LOADING_ONE);
            commit(types.CLIENT_CONFIGURATION_SHOW_SET_RETRIEVED_ONE, data);
        })
        .catch((e) => {
            commit(types.CLIENT_CONFIGURATION_FINISH_LOADING_ONE);
            commit(types.CLIENT_CONFIGURATION_SHOW_SET_ERROR, e.message);
        });
};
