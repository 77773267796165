<template></template>

<script>
import { Map } from "mapbox-gl";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    name: "MunicipalService",
    props: {
        map: Map,
    },

    methods: {
        ...mapActions({
            highlight: "municipalService/highlight",
            unHighlight: "municipalService/unHighlight",
            retrieveAll: "municipalService/retrieveAll",
        }),
        initializeMap: function () {
            this.map.on("load", () => {
                this.map.loadImage(
                    require("@/assets/playgrounds/marker.png"),
                    (error, image) => {
                        if (error) throw error;
                        this.map.addImage("municipal_service_marker", image, {
                            sdf: true,
                        });
                        this.map.addSource("municipal_service_source", {
                            type: "geojson",
                            promoteId: "uuid",
                            data: {
                                type: "FeatureCollection",
                                features: this.municipalServices,
                            },
                        });
                        this.map.addLayer({
                            id: "municipal_service_layer",
                            type: "symbol",
                            source: "municipal_service_source",
                            layout: {
                                "icon-image": "municipal_service_marker",
                                "icon-anchor": "bottom",
                                "icon-size": 0.4,
                            },
                            paint: {
                                "text-color": "#ffffff",
                                "icon-color": [
                                    "case",
                                    [
                                        "boolean",
                                        ["feature-state", "highlight"],
                                        false,
                                    ],
                                    "#4264FB",
                                    "#748dfc",
                                ],
                                "icon-opacity": [
                                    "case",
                                    [
                                        "boolean",
                                        ["feature-state", "highlight"],
                                        false,
                                    ],
                                    0.9,
                                    0.7,
                                ],
                            },
                        });
                    }
                );
            });
            this.map.on("mouseenter", "municipal_service_layer", (e) => {
                this.highlight(e.features[0].properties.uuid);
                this.map.getCanvas().style.cursor = "pointer";
            });
            this.map.on("mouseleave", "municipal_service_layer", () => {
                this.unHighlight();
                this.map.getCanvas().style.cursor = "";
            });
            this.map.on("click", "municipal_service_layer", (e) => {
                this.$router.push({
                    name: "municipal_service_detail",
                    params: {
                        municipalServiceUuid: e.features[0].properties.uuid,
                    },
                });
            });
        },
    },

    computed: {
        ...mapState("municipalService", [
            "municipalServices",
            "highlightedMunicipalServiceUuid",
        ]),
        ...mapGetters("municipalService", ["municipalServiceUuids"]),
    },

    watch: {
        map: {
            handler(map) {
                if (null !== map) {
                    this.initializeMap();
                }
            },
        },
        municipalServiceUuids: {
            handler() {
                if (
                    null !== this.map &&
                    typeof this.map.getSource("municipal_service_source") !==
                        "undefined"
                ) {
                    this.map.getSource("municipal_service_source").setData({
                        type: "FeatureCollection",
                        features: this.municipalServices,
                    });
                }
            },
        },
        highlightedPlaygroundUuid: {
            handler(
                newHighlightedMunicipalServiceUuid,
                oldHighlightedMunicipalServiceUuid
            ) {
                if (oldHighlightedMunicipalServiceUuid !== null) {
                    this.map.setFeatureState(
                        {
                            source: "municipal_service_source",
                            id: oldHighlightedMunicipalServiceUuid,
                        },
                        { highlight: false }
                    );
                }
                if (newHighlightedMunicipalServiceUuid !== null) {
                    this.map.setFeatureState(
                        {
                            source: "municipal_service_source",
                            id: newHighlightedMunicipalServiceUuid,
                        },
                        { highlight: true }
                    );
                }
            },
        },
    },

    created() {
        this.retrieveAll();
    },
};
</script>

<style></style>
