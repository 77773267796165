<template>
    <Period
        v-if="periods.length > 0"
        v-for="period in periods"
        :period="period"
    />
    <div v-else class="truncate px-2">geschlossen</div>
</template>
<script>
import Period from "./Period";

export default {
    name: "Periods",
    components: { Period },
    props: {
        periods: {},
    },
};
</script>
