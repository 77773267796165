<template>
    <div class="overflow-hidden">
        <h4 class="text-gray-900 font-bold">{{ openingHours.title }}</h4>
        <table class="text-sm">
            <tr v-for="day in openingHours.data.regular">
                <template v-if="day.periods.length > 0">
                    <td class="pr-2 truncate align-top">
                        {{ getDayShort(day.from) }}
                        <template v-if="day.to !== null"
                            >- {{ getDayShort(day.to) }}</template
                        >
                    </td>
                    <td class="flex flex-wrap align-top">
                        <Periods :periods="day.periods" />
                    </td>
                </template>
            </tr>
            <tr
                v-if="openingHours.data.exceptions !== null"
                v-for="(periods, date) in openingHours.data.exceptions"
            >
                <td class="pr-2 truncate align-top">
                    {{ date }}
                </td>
                <td class="flex flex-wrap align-top">
                    <Periods :periods="periods" />
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import Periods from "./Periods";

export default {
    name: "OpeningHours",
    components: { Periods },
    props: {
        openingHours: {},
    },
    methods: {
        getDayShort: (weekday) =>
            ({
                monday: "MO",
                tuesday: "DI",
                wednesday: "MI",
                thursday: "DO",
                friday: "FR",
                saturday: "SA",
                sunday: "SO",
            }[weekday]),
    },
};
</script>
