<template>
    <div class="space-y-5">
        <h3 class="text-lime-400">{{ department.title }}</h3>
        <Description :description="department.description" />
        <AddressCollection
            v-if="department.addresses.length > 0"
            :address-collection="department.addresses"
        />
        <ContactElementCollection
            :contact-elements="department.contactElements"
        />
        <OpeningHours
            v-if="department.openingHours !== null"
            :opening-hours="department.openingHours"
        />
        <ContactPersonCollection
            :contact-person-collection="department.contactPersons"
        />
    </div>
</template>

<script>
import ContactPersonCollection from "./ContactPersonCollection";
import ContactElementCollection from "./ContactElementCollection";
import Description from "./Description";
import AddressCollection from "./AddressCollection";
import OpeningHours from "./OpeningHours/OpeningHours";

export default {
    name: "Department",
    components: {
        OpeningHours,
        AddressCollection,
        Description,
        ContactElementCollection,
        ContactPersonCollection,
    },
    props: {
        department: Object,
    },
};
</script>
