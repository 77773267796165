import fetch from "@/utils/api/fetch";
import * as types from "./mutation_types";

export const retrieveAll = ({ commit }) => {
    commit(types.MUNICIPAL_SERVICE_START_LOADING_ALL);

    return fetch(`api/municipal_services`)
        .then((response) => response.json())
        .then((data) => {
            commit(types.MUNICIPAL_SERVICE_FINISH_LOADING_ALL);
            commit(
                types.MUNICIPAL_SERVICE_SHOW_SET_RETRIEVED_ALL,
                data["hydra:member"]
            );
        })
        .catch((e) => {
            commit(types.MUNICIPAL_SERVICE_FINISH_LOADING_ALL);
            commit(types.MUNICIPAL_SERVICE_SHOW_SET_ERROR, e.message);
        });
};

export const highlight = ({ commit }, uuid) => {
    commit(types.MUNICIPAL_SERVICE_HIGHLIGHT, uuid);
};

export const unHighlight = ({ commit }) => {
    commit(types.MUNICIPAL_SERVICE_UN_HIGHLIGHT);
};
