export const MUNICIPAL_SERVICE_FINISH_LOADING_ALL =
    "MUNICIPAL_SERVICE_FINISH_LOADING_ALL";
export const MUNICIPAL_SERVICE_SHOW_SET_ERROR =
    "MUNICIPAL_SERVICE_SHOW_SET_ERROR";
export const MUNICIPAL_SERVICE_SHOW_SET_RETRIEVED_ALL =
    "MUNICIPAL_SERVICE_SHOW_SET_RETRIEVED_ALL";
export const MUNICIPAL_SERVICE_SHOW_TOGGLE_LOADING =
    "MUNICIPAL_SERVICE_SHOW_TOGGLE_LOADING";
export const MUNICIPAL_SERVICE_START_LOADING_ALL =
    "MUNICIPAL_SERVICE_START_LOADING_ALL";
export const MUNICIPAL_SERVICE_HIGHLIGHT = "MUNICIPAL_SERVICE_HIGHLIGHT";
export const MUNICIPAL_SERVICE_UN_HIGHLIGHT = "MUNICIPAL_SERVICE_UN_HIGHLIGHT";
