<template>
    <div class="border rounded p-2 text-sm overflow-hidden">
        <h4 class="font-semibold">
            {{ address.title }}
        </h4>
        <div>
            {{ address.line1 }}
        </div>
        <div>
            {{ address.line2 }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Address",
    props: {
        address: Object,
    },
};
</script>
