<template>
    <div>
        <h1 class="text-sm font-semibold text-gray-900">
            {{ propertyCollection.title }}
        </h1>
        <table class="min-w-full">
            <tbody class="divide-y divide-gray-200">
                <tr
                    v-for="property in propertyCollection.properties"
                    :key="property.name"
                >
                    <td class="text-sm text-gray-900 align-top py-1 pr-2 w-1/3">
                        {{ property.name }}
                    </td>
                    <td class="text-sm text-gray-900 align-top py-1 w-2/3">
                        {{ property.value }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "PropertyCollection",
    props: {
        propertyCollection: Object,
    },
};
</script>
